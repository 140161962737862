import { computed, defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import { jumpIndexUrl } from "@/utils";
import { globalStorage } from "@/utils/storage/base";
export default defineComponent({
    name: "AuthCorpSelect",
    components: {},
    setup() {
        const { currentRoute, push } = useRouter();
        const store = useStore();
        const form = reactive({
            corpname: "",
            industry: "",
            mobile: "",
            token: "",
            password: "",
            scaletype: "",
            region: "",
            recommendcode: "",
        });
        const corps = computed(() => globalStorage.getFormLocal("select_corps")
            ? JSON.parse(globalStorage.getFormLocal("select_corps"))
            : []);
        const token = computed(() => globalStorage.getFormLocal("select_token") ?? "");
        // 微信code码
        const wechatcode = globalStorage.getFormLocal("login_wechat_code") ?? '';
        // 选择企业
        function handleCorpSelect(row) {
            if (!token.value)
                return;
            store
                .dispatch("loginWithCorpAction", {
                enginecode: row.corpcode,
                jwt: token.value,
            })
                .then(res => {
                // 清空code码 防止返回页面报失效
                globalStorage.setToLocal("login_wechat_code", "");
                jumpIndexUrl(row.corpcode);
            })
                .catch((errmsg) => {
                Toast(errmsg);
            })
                .finally(() => {
                globalStorage.removeFormLocal("select_token");
                globalStorage.removeFormLocal("select_corps");
            });
        }
        return {
            form,
            corps,
            token,
            handleCorpSelect,
        };
    },
});
